import { createSlice } from "@reduxjs/toolkit";

const NavigationStatusSlice = createSlice( {
    name : 'navigationStatus',
    initialState : false,
    reducers : {
        disableNavigation : () => false,
        navigationToggle : (state) =>  (state === true) ? false : true 
        
    }
})


export const { disableNavigation , enableNavigation , navigationToggle } = NavigationStatusSlice.actions ;

export default NavigationStatusSlice.reducer ;
