import HomeSection2Point from "../../components/HomeSection2Point/HomeSection2Point";
import "./HomeSection2.css" ;


export default function HomeSection2() {

    const Points = [ "Explore a pizza paradise with diverse favorites!",
                     "Savor authentic flavors with homemade sauce and traditional bases.",
                     "Signature stunners: Scallopine, Parmagiana, Saltimbocca, Cacciatore.",
                     "Pasta perfection: Fettuccine, Spaghetti, Penne, Lasagne, Cannelloni.",
                     "Dive into Ravioli, Tortellini & Gnocchi delights.",
                     "Elevate with Garlic Bread and Focaccias in Tomato & Olive, Bacon, Pesto, Mediterranean flavors."
        
    ]
  return (
    <div className="HomeSection2">
        <div className="HomeSection2Overlay"></div>
        
        <div className="HomeSection2ContentAreaContainer">

            <div className="HomeSection2Header">Welcome to La Cucina</div>

            <div className="HomeSection2Content">
                <div className="HomeSection2Content1">
                    "Embark on a culinary journey at Lacucina,
                    where the essence of Italy comes alive in every bite.
                    Our kitchen crafts a symphony of flavors, bringing you not just pizzas and pastas,
                    but a gastronomic adventure that transcends Kirwan and envelopes your senses.
                    Delight in the artistry of our authentic Italian creations and specialty dishes.
                    The table is set, the aroma beckons—immerse yourself in the magic.
                    Seize the moment, order now, and let your taste buds dance in the embrace of true Italian indulgence!"
                </div>

                <div className="HomeSection2Content2">
                    {Points.map( (data , index) => <HomeSection2Point Content={data} key={index}/> )}                
                </div>

            </div>
        </div>
    </div>
  )
}
 