import "./CopyrightFooter.css";

export default function CopyrightFooter() {
	const date = new Date();

	const title = "© " + date.getFullYear() + " Lacucina. All Rights Reserved.";
	return (
		<div className="CopyrightFooter">
			<p>{title}</p>
		</div>
	);
}
