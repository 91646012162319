import { useDispatch, useSelector } from "react-redux";
import "./OrderNow.css";
import HamBurgerIcon from "../../components/HamBurgerIcon/HamBurgerIcon";
import { disableOrderNow } from "../../redux/features/OrderNowSlice";

import MenuLogIcon from "../../assets/menuLog.png";
import UberEatsIcon from "../../assets/uberEats.png";
import DoorDashIcon from "../../assets/doorDash.png";



import React from 'react'

export default function OrderNow() {
  const OrderNowState = useSelector((state) => state.OrderNowStatus);
  const dispatch = useDispatch();

  const OrderNowOptions = [
		{
			url: "https://www.menulog.com.au/restaurants-la-cucina-pizza-and-pasta-house/menu",
			image: MenuLogIcon,
			title: "MenuLog",
		},
		{
			url: "https://www.ubereats.com/store/la-cucina-pizza-%26-pasta-house/ZncBr3wOWXaUEb5bZf2RwA",
			image: UberEatsIcon,
			title: "UberEats",
		},
		{
			url: "https://www.doordash.com/store/la-cucina-pizza-and-pasta-house-kirwan-23480902/",
			image: DoorDashIcon,
			title: "DoorDash",
		},
  ];
  return (
		<div className={OrderNowState ? "OrderNow Active" : "OrderNow"}>
			<div
				onClick={() => dispatch(disableOrderNow())}
				className="OrderNowCloseIcon"
			>
				<HamBurgerIcon status="HamBurgerIcon active" />
			</div>
			<div className="OrderNowOptions">
				{OrderNowOptions.map((data, index) => (
					<a
						href={data.url}
						key={index}
						target="_blank"
						rel="noreferrer"
						onClick={() => {
							dispatch(disableOrderNow());
						}}
					>
						<img
							src={data.image}
							alt={data.title}
							className="OrderNowIconImage"
						/>
					</a>
				))}
			</div>
		</div>
  );
}

