import "./HomeSection1.css"
import LogoName from "./../../assets/name.png" ;
import CTAButton from "../../components/CTAButton/CTAButton";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { enableOrderNow } from "../../redux/features/OrderNowSlice";


export default function HomeSection1() {
    const dispatch = useDispatch() ;
  return (
		<div className="HomeSection1">
			<div className="HomeSection1ContentArea">
				<img
					src={LogoName}
					alt="Logo Name"
					className="HomeSection1LogoName"
				/>
				<div className="HomeSection1Heading">
					The Unrivaled Italian Fiesta
				</div>
				<div className="HomeSection1Description">
					"At Lacucina, our pizzas are a symphony of freshness —
					crafted from lively dough, vibrant ingredients, and crowned
					with a melody of toppings."
				</div>
				<div className="HomeSection1CTAButtons">
					<Link to="/menu">
						<CTAButton
							Name="VIEW MENU"
							color="accent2"
						/>
					</Link>
					<div onClick={() => dispatch(enableOrderNow())}>
						<CTAButton
							Name="ORDER NOW"
							color="accent1"
						/>
					</div>
				</div>
			</div>
		</div>
  );
}
