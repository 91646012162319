import { useState, useEffect } from "react";
import MenuSectionCard from "../../components/MenuSectionCard/MenuSectionCard";
import MenuSectionHead from "../../components/MenuSectionHead/MenuSectionHead";
import Footer from "../../sections/Footer/Footer";
import "./Menu.css";

import Data from "./../../Menu.json";

export default function Menu() {
	const MenuData = Data;

	const MenuSections = Object.keys(MenuData);

	const [MenuSectionName, setMenuSectionName] = useState({
		Name: MenuSections[0],
		Info: MenuData[MenuSections[0]].Info,
	});


	

	const MenuSectionNavigateButtonHandler = (e) => {
		setMenuSectionName({
			Name: e.target.innerHTML,
			Info: MenuData[e.target.innerHTML].Info,
		});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
        
	}, [MenuSectionName]);
	return (
		<div className="Menu">
			<div className="MenuSectionHeader">
				<div className="MenuSectionHeaderOverlay"></div>
				<div className="MenuSectionHeaderContent">
					<div className="MenuSectionHeaderHeading">Our Menu</div>
					<div className="MenuSectionHeaderDescription">
						"Experience the artistry of Italian cuisine, where every
						dish is a masterpiece crafted freshly with love and
						tradition."
					</div>
				</div>
			</div>

			<div className="MenuSectionNavigateButtonsWrapper">
				{MenuSections.map((section, index) => (
					<span
						className={
							MenuSectionName.Name === section
								? "MenuSectionNavigateButton Active"
								: "MenuSectionNavigateButton"
						}
						key={index}
						onClick={MenuSectionNavigateButtonHandler}
					>
						{section}
					</span>
				))}
			</div>

			<div className="MenuItemsSectionWrapper">
				<MenuSectionHead
					Heading={MenuSectionName.Name}
					Description={MenuSectionName.Info}
				/>
				<div className="MenuItemsSection">
					
					{(MenuData[MenuSectionName.Name].Items)?.map((item, index) => {
						return (
							<MenuSectionCard
								key={index}
								Name={item.Name ? item.Name : null}
								Description={item.Description  ? item.Description : null}
								Image={item.Image ? "./Menu/"+item.Image : "./NoImage.jpg"}
								prices={item.Prices ? item.Prices : null}
							/>
						);
					})}
				</div>
			</div>

			<Footer />
		</div>
	);
}
