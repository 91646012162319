import Features from "../../sections/Features/Features";
import Footer from "../../sections/Footer/Footer";
import HomeSection1 from "../../sections/HomeSection1/HomeSection1";
import HomeSection2 from "../../sections/HomeSection2/HomeSection2";
import HomeSection4 from "../../sections/HomeSection4/HomeSection4";
import "./Home.css";

export default function Home() {
	return (
		<div className="Home">
			<div className="HomePageBackgroundImgContainer">
				<video
					autoPlay
					loop
					muted
					className="home_background_animation"
				>
					<source
						src="back_ground.webm"
						type="video/webm"
					/>
					Your browser does not support the video tag.
				</video>
			</div>
			<HomeSection1 />
			<HomeSection2 />
			<Features />
			<HomeSection4 />
			<Footer />
		</div>
	);
}
