import { configureStore } from "@reduxjs/toolkit";
import NavigationStatusReducer from "./features/NavigationStatusSlice";
import OrderNowReducer from "./features/OrderNowSlice";
import CustomAlertReducer from "./features/CustomAlertStatusSlice" ;

export const Store = configureStore({
    reducer : {
        NavigationStatus : NavigationStatusReducer ,
        OrderNowStatus : OrderNowReducer ,
        CustomAlert : CustomAlertReducer ,
        

        
    }
})