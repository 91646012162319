import "./Navigation.css";
import Logo from "./../../assets/logo.png";
import { NavLink } from "react-router-dom";
import NavOrderNowButton from "../../components/NavOrderNowButton/NavOrderNowButton";
import HamBurgerIcon from "../../components/HamBurgerIcon/HamBurgerIcon";
import { useSelector, useDispatch } from "react-redux";
import {
	navigationToggle,
	disableNavigation,
} from "../../redux/features/NavigationStatusSlice";
import { enableOrderNow } from "../../redux/features/OrderNowSlice";

export default function Navigation() {
	const dispatch = useDispatch();

	const isNavigationEnabled = useSelector((state) => state.NavigationStatus);

	return (
		<>
			<nav className="Navigation">
				<div className="NavigationBar">
					<NavLink
						to="/"
						className="NavLogo"
						onClick={() => dispatch(disableNavigation())}
					>
						<img
							src={Logo}
							alt="Logo"
						/>
					</NavLink>
					<div
						className={
							!isNavigationEnabled
								? "NavRight "
								: "NavRight Mobile"
						}
					>
						<div
							className="NavLinks"
							onClick={() => dispatch(disableNavigation())}
						>
							<NavLink to="/">HOME</NavLink>
							<NavLink to="/menu">MENU</NavLink>
							{/* <NavLink to="/about">ABOUT</NavLink>
							<NavLink to="/reviews">REVIEWS</NavLink> */}
							<NavLink to="/contact_us">CONTACT US</NavLink>
						</div>

						<span className="NavButtonSeperator"> | </span>
						<NavOrderNowButton
							onClick={() => {
								dispatch(enableOrderNow());
								dispatch(disableNavigation());
							}}
						/>
					</div>
					<div
						className="HamburgerMenuIcon"
						onClick={() => dispatch(navigationToggle())}
					>
						<HamBurgerIcon
							status={isNavigationEnabled ? true : false}
						/>
					</div>
				</div>
				<div
					className={
						isNavigationEnabled
							? "NavigationOverLayEnabled"
							: "NavigationOverLayDisabled"
					}
					onClick={() => dispatch(disableNavigation())}
				></div>
			</nav>
		</>
	);
}
