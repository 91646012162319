import { createSlice } from "@reduxjs/toolkit";

const OrderNowSlice = createSlice( {
    name : 'orderNow',
    initialState : false,
    reducers : {
        disableOrderNow : () => false,
        enableOrderNow : () => true, 
        
    }
})

export const { disableOrderNow, enableOrderNow } = OrderNowSlice.actions ;

export default OrderNowSlice.reducer ;