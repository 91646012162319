import "./CTAButton.css" ;

export default function CTAButton(props) {
    const Color = props.color
        
  return (
    <div className={Color.toLowerCase() === "accent1" ? "CTAButton Red" :"CTAButton Green" } >
            {props.Name}
    </div>
  )
}
