import Footer from "../../sections/Footer/Footer";
import "./About.css";

export default function About() {
  return (
    <div>
      About
      <Footer />
    </div>
  )
}
