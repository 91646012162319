import "./MenuSectionCard.css";
import MenuPrice from "../MenuPrice/MenuPrice";

export default function MenuSectionCard(props) {
	return (
		<div className="MenuSectionCard">
			<div>
				<img
					src={props.Image}
					alt={props.Name}
					className="MenuSectionCardImage"
				/>
			</div>
			<div className="MenuSectionCardContentWrapper">
				<span className="MenuSectionCardItemName">{props.Name}</span>
				<span className="MenuSectionCardItemDescription">
					{props.Description}
				</span>
				<div className="MenuSectionCardPrices">
					{props.prices.map((price , index) => {
						return <MenuPrice price={price} key={index}/>;
					})}
				</div>
			</div>
		</div>
	);
}
