import "./MenuPrice.css";
export default function MenuPrice(props) {
	return (
		<div className="MenuPrice">
			<div className="MenuPriceItem">
				<span className="MenuPriceItemName">
					{props.price.Size}
				</span>
				<span className="MenuPriceItemCost">{props.price.Cost}</span>
			</div>
		</div>
	);
}
