import "./Features.css" ;

import FeaturesSectionCard from "./../../components/FeaturesSectionCard/FeaturesSectionCard";
import { useDispatch } from "react-redux";
import { enableOrderNow } from "../../redux/features/OrderNowSlice";

export default function Features() {

    const dispatch = useDispatch() ;
    const FeaturesCardContent = [
        {   Heading : "Order Online",
            Content : "Book online and pickup when ready, with our easy online ordering menu. Place your order now. We’ll call you to confirm, and tell you how when it will be ready for pickup.",
            ButtonName : "ORDER NOW" ,
            CTAButtonClickHandler : () =>  dispatch(enableOrderNow()),             
        } ,
        {   Heading : "Advance Order",
            Content : "Order your meal hours or days in advance, to avoid the queue and pickup right when it suits you. Simply mention the pickup date and time in your order notes. Easy!",
            ButtonName : "ORDER AHEAD" ,
            CTAButtonClickHandler : () =>  window.location.href = "tel:+61433874507"
        }
    ]

  return (
    <div className="Features">
        <div className="FeaturesContent">

            {FeaturesCardContent.map( (data , index) => <FeaturesSectionCard Heading={data.Heading} 
                                                            Content={data.Content} 
                                                            ButtonName={data.ButtonName}
                                                            Color="accent2"
                                                            CTAButtonClickHandler={data.CTAButtonClickHandler}
                                                            key={index}/>   
            )}
        </div>
        
    </div>
  )
}
