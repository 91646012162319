import "./NavOrderNowButton.css"

export default function NavOrderNowButton(props) {
  
  return (
    <div className="NavOrderNowButton">
        <span onClick={props.onClick}>Order Now </span>
        <div className="AnimationColor"></div>
    </div>
  )
}
