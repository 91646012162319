import "./PageNotFound.css";
import { Link } from "react-router-dom";

import error404Image from "../../assets/404.png";
import CTAButton from "../../components/CTAButton/CTAButton";

export default function PageNotFound() {
	return (
		<div className="PageNotFound">
			<div>
				<img
					src={error404Image}
					alt="404ErrorImage"
					className="error404Image"
				/>
				<h1>Page Not Found</h1>
				<p>
					Seems Like the Page you are looking for doesn't exists or
					may be moved
				</p>
			</div>

			<Link to="/">
				<CTAButton
					color="accent2"
					Name="Back to Home Page"
				/>
			</Link>
		</div>
	);
}
