import ContactUsCard from "../../components/ContactUsCard/ContactUsCard";
import Footer from "../../sections/Footer/Footer";
import "./ContactUs.css";

export default function ContactUs() {
	
	return (
		<div className="ContactUs">
			<div className="ContactUsSection1">
				<div className="ContactUsSection1Overlay">
					<div className="ContactUsSection1Heading">Contact Us</div>
					<div className="ContactUsSection1Description">
						Craving a slice of perfection or have a cheesy question?
						Contact us, because every great pizza begins with a
						conversation!
					</div>
				</div>
			</div>

			<div className="ContactUsDetailsCardSection">
				<ContactUsCard
					Name="Call"
					Phone={["+61433874507", "+6147732416"]}
				/>

				<a
					href="mailto:lacucinakirwan@gmail.com"
					className="FooterContactUsContainerEmailUsSet"
				>
					<ContactUsCard Name="Email" />
				</a>

				<a
					href="https://maps.app.goo.gl/RPzinyQwk63F3bPWA"
					className="FooterContactUsContainerLocationSet"
				>
					<ContactUsCard Name="Location" />
				</a>
				<ContactUsCard Name="Clock" />
			</div>

			<div className="ContactUsMapSection">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.298314634613!2d146.73965037483032!3d-19.312857319387337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6bd5fa0ab1bf70c9%3A0x3dd0b276869b965f!2sLa%20Cucina%20Pizza%20%26%20Pasta%20House!5e0!3m2!1sen!2sin!4v1703101493348!5m2!1sen!2sin"
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
					className="ContactUsMapSectionMapFrame"
					title="LocationFrame"
				></iframe>
			</div>

			<Footer />
		</div>
	);
}
