import CTAButton from "./../CTAButton/CTAButton";
import "./FeaturesSectionCard.css" ;

// ButtonName , Heading , Content , Color Props Needed for this Component

export default function FeaturesCard(props) {
  return (
    <div className="FeaturesSectionCard">
        <div className="FeaturesSectionCardCardHeader">{props.Heading}</div>
        <div className="FeaturesSectionCardCardContent">{props.Content}</div>
        <div onClick={props.CTAButtonClickHandler}>
          <CTAButton Name={props.ButtonName} color={props.Color} />        
        </div>
    </div>
  )
}
