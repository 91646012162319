import Footer from "../../sections/Footer/Footer";
import "./Reviews.css" ;

export default function Reviews() {
  return (
    <div>Reviews
      <Footer />
    </div>
  )
}
