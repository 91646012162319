import { createSlice} from "@reduxjs/toolkit";

const CustomAlertSlice = createSlice( {
    name : 'customAlertSlice',
    initialState : {isEnabled : true , message : "Hellosdkjsdgjkddkjbdfbjdfklbjfdblfdjbdflkbnfdkbndfbkdfnbjkfbnd"},
    reducers : {
        enableCustomAlert : (state, action) => {
            state.isEnabled = true ;
            state.message = action.payload ;
        },
        disableCustomAlert : (state) => {
            state.isEnabled = false ;
            state.message = "" ;
        },      
    }

}) ;

export const { disableCustomAlert, enableCustomAlert } = CustomAlertSlice.actions ;

export default CustomAlertSlice.reducer ;