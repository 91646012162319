import "./FooterContactUsContainer.css";
import CallUsImg from "./../../assets/CallUs.png";
import EmailImg from "./../../assets/Email.png";
import LocationPinImg from "./../../assets/Location.png";

export default function FooterContactUsContainer() {
	return (
		<div className="FooterContactUsContainer">
			<div className="FooterContactUsHeader">CONTACT US</div>

			<a
				href="tel:+6147732416"
				className="FooterContactUsContainerCallUsSet"
			>
				<img
					src={CallUsImg}
					alt="Call US Img"
					className="CallUsImg"
				/>
				<div>
					<div className="FooterContactUsContainerCallUsHeader">
						Call Us
					</div>
					<div className="FooterContactUsContainerCallUsData">
						+61 4773 2416
					</div>
				</div>
			</a>

			<a
				href="mailto:lacucinakirwan@gmail.com"
				className="FooterContactUsContainerEmailUsSet"
			>
				<img
					src={EmailImg}
					alt="Email Us Img"
					className="EmailUsImg"
				/>
				<div>
					<div className="FooterContactUsContainerEmailUsHeader">
						Email Us
					</div>
					<div className="FooterContactUsContainerEmailUsData">
						lacucinakirwan@gmail.com
					</div>
				</div>
			</a>

			<a
				href="https://maps.app.goo.gl/RPzinyQwk63F3bPWA"
				className="FooterContactUsContainerLocationSet"
			>
				<img
					src={LocationPinImg}
					alt="Location Pin Img"
					className="LocationPinImg"
				/>
				<div>
					<div className="FooterContactUsContainerLocationHeader">
						Our Location
					</div>
					<div className="FooterContactUsContainerLocationData">
						1 Forest Avenue Kirwan - Corner Ross River Road
					</div>
				</div>
			</a>
		</div>
	);
}
