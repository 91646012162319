import "./ContactUsCardCallUs.css";

export default function ContactUsCardCallUs(props) {
    
	return (
		<div className="ContactUsCardCallUs">
			{props.phone?.map((phone, index) => {
				return (
					<a
						href={"tel:" + phone}
						className="FooterContactUsContainerCallUsSet"
					>
						{phone}
					</a>
				);
			})}
		</div>
	);
}
