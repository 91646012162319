import "./ContactUsCard.css" ;
import CallUsIcon from "./../../assets/CallUs.png" ;
import EmailIcon from "./../../assets/Email.png" ;
import LocationIcon from "./../../assets/Location.png" ;
import ClockIcon from "./../../assets/clock.png" ;
import ContactUsCardCallUs from "./ContactUsCardCallUs/ContactUsCardCallUs";
import ContactUsCardEmail from "./ContactUsCardEmail/ContactUsCardEmail";
import ContactUsCardLocation from "./ContactUsCardLocation/ContactUsCardLocation";
import ContactUsCardClock from "./ContactUsCardClock/ContactUsCardClock";



export default function ContactUsCard(props) {
    const Icon = () => {
        switch (props.Name) {
          case "Call":
            return CallUsIcon;
          case "Email":
            return EmailIcon;
          case "Location":
            return LocationIcon;
          case "Clock":
            return ClockIcon;
          default:
            return null;
        }}

    const Content = () => {
        switch (props.Name) {
            case "Call":
              return <ContactUsCardCallUs phone={props.Phone}/>;
            case "Email":
              return <ContactUsCardEmail />;
            case "Location":
              return <ContactUsCardLocation />;
            case "Clock":
              return <ContactUsCardClock />;
            default:
              return null;
        }

    }

        
               
  return (
    <div className="ContactUsCard">  
        <div className="ContactUsCardImageContainer">
            <img src={Icon()} alt={props.Name} className={"ContactUsCard"+props.Name+"Icon"} />
        </div>
        <div className="ContactUsCardContentContainer">
                {Content()}
        </div>
    </div>
  )
}