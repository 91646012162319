import "./ContactUsCardClock.css" ;

export default function ContactUsCardClock() {
    const OpenHoursContent = [
        {day : "Sunday" , timings : "4:30 P.M to 9:00 P.M"} , 
        {day : "Monday" , timings : "4:30 P.M to 9:00 P.M"} , 
        {day : "Tuesday" , timings : "4:30 P.M to 9:00 P.M"} ,
        {day : "Wednesday" , timings : "4:30 P.M to 9:00 P.M"} , 
        {day : "Thursday" , timings : "4:30 P.M to 9:00 P.M"} ,
        {day : "Friday" , timings : "4:30 P.M to 9:00 P.M"} , 
        {day : "Saturday" , timings : "4:30 P.M to 9:00 P.M"}
    ]

    const d = new Date()
  return (
    <div className="ContactUsCardClock">
        {OpenHoursContent.map( (item , index) =>
                <div className= {(d.getDay() === (index)) ? "ContactUsCardClockDataContainer Active" : "ContactUsCardClockDataContainer"}  key={index}>
                    <span className="FooterOpenHoursDayName">{item.day}</span>      
                    <span> - </span>
                    <span> {item.timings} </span>
                </div>
            )}

    </div>
  )
}
