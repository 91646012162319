import "./Footer.css"
import Logo from "./../../assets/v_logo.png"

import FooterContactUsContainer from "../../components/FooterContactUsContainer/FooterContactUsContainer" ;
import FooterOpensHoursContainer from "../../components/FooterOpensHoursContainer/FooterOpensHoursContainer" ;


export default function Footer() {
  return (
    <div className="Footer">
      <div className="FooterContainer">
        <FooterContactUsContainer/>
        <img src={Logo} alt="Logo" className="FooterLogo"/>
        <FooterOpensHoursContainer />   
      </div>
    </div>
  )
}
