import "./HomeSection4.css" ;

export default function HomeSection4() {
  return (
    <div className="HomeSection4">
        
        <div className="HomeSection4Overlay"></div>
        <div className="HomeSection4Content">
            <div className="HomeSection4Header"><div>Serving Kirwan for</div> <div>30 Years</div></div>
            <div className="HomeSection4Description">
                Since 1993, La Cucina Pizza and Pasta House has been Townsville's hub for authentic Italian cuisine. 
                Enjoy a mix of classic and innovative dishes with our regularly updated menu, loyalty perks, 
                and a welcoming atmosphere. Connect with us online for a wider reach, 
                and our staff is committed to continuous improvement. 
                Count on La Cucina for consistently delicious and trend-forward dining.
            </div>
        
        </div>
    </div>
  )
}
