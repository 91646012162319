import "./HomeSection2Point.css" ;
import PointsIcon from "./../../assets/point_icon.png" ;

export default function HomeSection2Point(props) {
  return (
    <div className="HomeSection2Point">
        <div className="HomeSection2PointContent">
            <img src={PointsIcon} alt="Points Icon" />
            <span>{props.Content}</span>
        </div>
    </div>
  )
}
